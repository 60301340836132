import React, { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';

import Text from 'components/Text';

import { IQuestion, ISurvey, ISurveyQuestionTypeEnum } from 'entities/surveys';

import Translate from 'translate/Translate';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import SelectOne from './questions/SelectOne';
import SelectMultiple from './questions/SelectMultiple';
import SlidingThree from './questions/SlidingThree';
import SlidingTen from './questions/SlidingTen';
import ShortFormAnswer from './questions/ShortFormAnswer';
import MultipleToggleAnswer from './questions/MultipleToggleAnswer';

import { ContainerStyles } from './styles';

interface SurveyQuestionsProps {
    getSurveyLoading: boolean;
    surveyData: ISurvey | null;
    scanId: string;
    getAllSurveys: () => void;
    surveyProgress: number;
    setSurveyProgress: (param: number) => void;
}

const SurveyQuestions = ({
    getSurveyLoading,
    surveyData,
    scanId,
    getAllSurveys,
    surveyProgress,
    setSurveyProgress,
}: SurveyQuestionsProps):JSX.Element => {
    const [questionsList, setQuestionsList] = useState<IQuestion[]>([]);
    const [stateSurveyId, setStateSurveyId] = useState('');

    const storedAnswers = Utils.LocalStorage.getAnswers();

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);

        window.addEventListener('popstate', handleGoBackEvent, false);

        return () => {
            window.removeEventListener('popstate', handleGoBackEvent, false);
        };
    }, [surveyProgress]);

    useEffect(() => {
        if (scanId) getAllSurveys();
    }, [scanId]);

    useEffect(() => {
        if (surveyData) {
            setQuestionsList(surveyData.questions);
            setStateSurveyId(surveyData.surveyId);
        }
    }, [surveyData]);

    const handleGoBackEvent = (ev: PopStateEvent) => {
        ev.preventDefault();

        if (surveyProgress > 0) {
            setSurveyProgress(surveyProgress - 1);

            // to manually disable the browser's default back button behavior by making it go forward one page when the user presses the back button
            window.history.go(1);
        } else {
            const goBackPromptMessage = Translate.t('goBackPromptMessage');
            const confirm = window.confirm(goBackPromptMessage);
            if (confirm) {
                NavActions.navBack();
                Utils.LocalStorage.clearAnswer();
            }
        }
    };

    if (getSurveyLoading) {
        return (
            <div style={ContainerStyles.spinnerContainer}>
                <Oval
                    height={200}
                    width={200}
                    color='black'
                    secondaryColor='#A5AAB5'
                />
            </div>
        );
    }

    if (!questionsList.length) {
        return (
            <div style={{ ...ContainerStyles.mainContainer, height: '100vh', color: 'black' }}>
                <Text>No questions found</Text>
            </div>
        );
    }

    return (
        <>
            {questionsList.map((item, index) => {
                const { type, id, question: toBeMassagedQuestion, options, image, loading } = item;

                const question = toBeMassagedQuestion;

                const isInView = index === surveyProgress;

                let answer: string | string[] | undefined;

                if (storedAnswers && storedAnswers[id]) {
                    answer = storedAnswers[id];
                }

                if (type === ISurveyQuestionTypeEnum.SelectOne) {
                    return (
                        <QuestionContainer $display={isInView} key={id}>
                            <SelectOne
                                key={id}
                                surveyId={stateSurveyId}
                                questionId={id}
                                question={question}
                                options={options}
                                images={image || []}
                                loading={loading || false}
                                selectedAnswer={answer as string | undefined}
                            />
                        </QuestionContainer>
                    );
                }

                if (type === ISurveyQuestionTypeEnum.SelectMultiple) {
                    return (
                        <QuestionContainer $display={isInView} key={id}>
                            <SelectMultiple
                                key={id}
                                surveyId={stateSurveyId}
                                questionId={id}
                                question={question}
                                options={options}
                                images={image || []}
                                loading={loading || false}
                                selectedAnswer={answer as string[] | undefined}
                            />
                        </QuestionContainer>
                    );
                }

                if (type === ISurveyQuestionTypeEnum.SlidingThree) {
                    return (
                        <QuestionContainer $display={isInView} key={id}>
                            <SlidingThree
                                key={id}
                                surveyId={stateSurveyId}
                                questionId={id}
                                question={question}
                                options={options}
                                images={image || []}
                                loading={loading || false}
                            />
                        </QuestionContainer>
                    );
                }

                if (type === ISurveyQuestionTypeEnum.SlidingTen) {
                    return (
                        <QuestionContainer $display={isInView} key={id}>
                            <SlidingTen
                                key={id}
                                surveyId={stateSurveyId}
                                questionId={id}
                                question={question}
                                options={options}
                                images={image || []}
                                loading={loading || false}
                            />
                        </QuestionContainer>
                    );
                }

                if (type === ISurveyQuestionTypeEnum.ShortFormAnswer) {
                    return (
                        <QuestionContainer $display={isInView} key={id}>
                            <ShortFormAnswer
                                key={id}
                                surveyId={stateSurveyId}
                                questionId={id}
                                question={question}
                                images={image || []}
                                loading={loading || false}
                            />
                        </QuestionContainer>
                    );
                }

                if (type === ISurveyQuestionTypeEnum.SelectOneWithShortFormAnswer) {
                    return (
                        <QuestionContainer $display={isInView} key={id}>
                            <SelectOne
                                key={id}
                                surveyId={stateSurveyId}
                                questionId={id}
                                question={question}
                                options={options}
                                images={image || []}
                                loading={loading || false}
                                withShortFormAnswer
                                selectedAnswer={answer as string | undefined}
                            />
                        </QuestionContainer>
                    );
                }

                return (
                    <QuestionContainer $display={isInView} key={id}>
                        <MultipleToggleAnswer
                            key={id}
                            surveyId={stateSurveyId}
                            questionId={id}
                            question={question}
                            options={options}
                            images={image || []}
                            loading={loading || false}
                        />
                    </QuestionContainer>
                );
            })}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    getSurveyLoading: Selectors.surveyGetAllSurveysAttempting(state),
    surveyData: Selectors.surveyGetAllSurveys(state),
    scanId: Selectors.homeGetScanId(state),
    surveyProgress: Selectors.surveyGetSurveyProgress(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getAllSurveys: () => dispatch(Actions.getAllSurveysAttempt()),
    setSurveyProgress: (param: number) => dispatch(Actions.surveySetProgress(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestions);

const QuestionContainer = styled.div<{ $display: boolean }>`
    display: ${props => (props.$display ? 'flex' : 'none')};
    width: 100%;
`;
